import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import AppRoutes from "./AppRoutes";
import Div100vh from 'react-div-100vh'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },

});

const App = () => {
  return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Div100vh>
              <AppRoutes />
        </Div100vh>
      </ThemeProvider>  );
};

export default App;
