export const siteData = [
    {
      id: '1',
      distance: '5',
      brandImg: 'shell_logo.png',
      siteName: 'Shell Haarrijn',
      siteImg: 'shell_site.jpg',
      chargeTime: '50',
      chargeSpeed: '50',
      price: '0,39',
      greenScore:'80%',
      chargeBoxId: 'CSDMKeuken001',
    },
    {
      id: '2',
      distance: '7',
      brandImg: 'bp_logo.png',
      siteName: 'Shell Haarrijn',
      siteImg: 'bp_site.jpg',
      chargeTime: '20',
      chargeSpeed: '50',
      price: '0,39',
      greenScore:'80%',
      chargeBoxId: 'CSDMKeuken001',
    },
    {
      id: '3',
      distance: '10',
      brandImg: 'ABB_logo.png',
      siteName: 'Shell Haarrijn',
      siteImg: 'abb_site.jpg',
      chargeTime: '20',
      chargeSpeed: '50',
      price: '0,39',
      greenScore:'80%',
      chargeBoxId: 'CSDMKeuken001',
    },
    {
      id: '4',
      distance: '5',
      brandImg: 'fastned_logo.png',
      siteName: 'Shell Haarrijn',
      siteImg: 'fastned_site.jpg',
      chargeTime: '50',
      chargeSpeed: '50',
      price: '0,39',
      greenScore:'80%',
      chargeBoxId: 'CSDMKeuken001',
    },
    {
      id: '5',
      distance: '17',
      brandImg: 'shell_logo.png',
      siteName: 'Shell Haarrijn',
      siteImg: 'shell_site.jpg',
      chargeTime: '50',
      chargeSpeed: '50',
      price: '0,39',
      greenScore:'80%',
      chargeBoxId: 'CSDMKeuken001',
    }
  ];

 export const service = [
    {
        id: '1',
        serviceName: 'Uber',
        subTitle: 'Taxi service',
        serviceImg: 'uber_logo.png',
        catecorie:'mobility',
        priceMin: '0,70',
    },
    {
      id: '2',
      serviceName: 'Shell Cafe',
      subTitle: 'Shell cafe',
      serviceImg: 'shell_cafe_logo.png',
      catecorie:'food',
      priceMin: '0,70',
    },
      {
        id: '3',
        serviceName: 'Gorillas',
        subTitle: 'Grocery delivery',
        serviceImg: 'gorillas_logo.png',
        catecorie:'food delivery',
        priceMin: '0,70',
      },
      {
        id: '4',
        serviceName: 'Share Now',
        subTitle: 'Car sharing',
        serviceImg: 'sharenow_logo.png',
        catecorie:'mobility',
        priceMin: '0,70',
      },
      {
        id: '5',
        serviceName: 'Fast clean service',
        subTitle: 'carwash',
        serviceImg: 'fcs_logo.png',
        catecorie:'carwash',
        priceMin: '0,70',
      },
      {
        id: '6',
        serviceName: 'Amazon',
        subTitle: 'Package delivery',
        serviceImg: 'amazon_logo.png',
        catecorie:'package delivery',
        priceMin: '0,70',
      },
  ];
