import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from '@mui/material';
import { styled } from "@mui/material";

export const FormInputText = ({ name, control, label }) => {
  const Item = styled(TextField)(({ theme }) => ({
    margin: '5%',
  }));
  return (
      <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
              <Item
                  helperText={error ? error.message : null}
                  size="small"
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  label={label}
                  variant="filled"
              />
          )}
      />
  );
};
