import React from 'react';
import { Box, Container, Button, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import Alert from '@mui/material/Alert';

import BotttomNavigation from '../../components/BottomNavigation';

function SiteDetail(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state.data;
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const waitForReservation = () => {
    let count = 0;
    const interval = setInterval(() => {
      count +=1;
      if (count > 3) {
        clearInterval(interval);
        setOpenSnackbar(true)
        setOpenBackdrop(false);
        return;
      }
      fetch(
          `https://charge01.eleqtramove.com/csat/api/connector-status/${data.chargeBoxId}`,
          {
            method: 'GET'
          })
      .then(response => response.json())
      .then(result => {
        if (result.status === "Reserved") {
          clearInterval(interval);
          navigate('/reservations', { state: { data } });
        }
      })
      .catch(error => {
        setOpenSnackbar(true)
        setOpenBackdrop(false);
      });
    }, 1000);
  }

  const handleReserveClick = () => {
    setOpenBackdrop(true);
    fetch(
        `https://charge01.eleqtramove.com/csat/api/chargers/${data.chargeBoxId}/reserve-now`,
        {
          method: 'PUT'
        })
    .then(response => {
      waitForReservation();
    })
    .catch(error => {
      setOpenBackdrop(false);
    });
  };

  const handleBackClick = (data) => {
    //here action to go back
    navigate('/chargingoffers');
  };

  return (
      <Box sx={{ ...styles.box }}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
            onClick={handleCloseSnackbar}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            open={openSnackbar}
          >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }} style={{ color: 'white'}}>
            Timeout for making the reservation
          </Alert>
        </Snackbar>
        <BotttomNavigation/>
        <Container sx={{ ...styles.siteImg, backgroundImage: `url(sites/${data.siteImg})` }}></Container>
        <Container sx={{ ...styles.contentHeader }}>
          <Container sx={{ ...styles.brandlogo }}>
            <Container sx={{ ...styles.logoInner, backgroundImage: `url(brand/${data.brandImg})` }}/>
          </Container>
          <div style={{ ...styles.headerInner, fontSize: '20px', fontWeight: 700 }}>{data.siteName}</div>
          <div style={{ ...styles.headerInner }}>
            <span style={{ fontSize: '26px' }}>{data.distance}<span style={{ fontSize: '16px' }}> &nbsp; km</span></span>
          </div>
        </Container>

        <Container sx={{ ...styles.contentRow }}>
          <div style={{ ...styles.contentBlok, fontWeight: 300 }}>
            <span style={{ fontSize: '12px', fontWeight: 700 }}>Charge time to 80%</span>
            <span style={{ fontSize: '34px', textAlign: 'right' }}>{data.chargeTime}<span
                style={{ fontSize: '25px' }}>&nbsp; min</span></span>
          </div>
          <div style={{ ...styles.contentBlok, fontWeight: 300 }}>
            <span style={{ fontSize: '12px', fontWeight: 700 }}>Chargespeed</span>
            <span style={{ fontSize: '34px', textAlign: 'right' }}>{data.chargeSpeed}<span
                style={{ fontSize: '25px' }}>&nbsp; kwh</span></span>
          </div>
        </Container>

        <Container sx={{ ...styles.contentRow }}>
          <div style={{ ...styles.contentBlok, fontWeight: 300 }}>
            <span style={{ fontSize: '12px', fontWeight: 700 }}>Price per kwh</span>
            <span style={{ fontSize: '34px', textAlign: 'right' }}>{`€ ${data.price}`}</span>
          </div>
          <div style={{ ...styles.contentBlok, fontWeight: 300 }}>
            <span style={{ fontSize: '12px', fontWeight: 700 }}>Green score</span>
            <span style={{ fontSize: '34px', textAlign: 'right' }}>{data.greenScore}</span>
          </div>
        </Container>

        <Container sx={{ ...styles.contentRow, justifyContent: 'space-around', marginTop: '20px' }}>
          <Button variant="outlined" startIcon={<ArrowBackIcon/>} onClick={() => handleBackClick()}>Back to list</Button>
          <Button variant="contained" startIcon={<ElectricBoltIcon/>} onClick={() => handleReserveClick(data)}>reserve</Button>


        </Container>


      </Box>
  );
}

const styles = {
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    backgroundColor: 'rgba(40,38,38,1)',
    overflow: 'hidden',
    padding: '0px',
  },
  siteImg: {
    width: '100%',
    height: '40%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  contentHeader: {
    width: '100%',
    height: '6%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    backgroundColor: 'rgba(40,38,38,1)',
    overflow: 'hidden',
    padding: '20px 10px 0px 120px ',
    marginBottom: '20px',
  },
  contenMain: {
    width: '100%',
    height: '54%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    backgroundColor: 'rgba(40,38,38,1)',
    overflow: 'hidden',
    padding: '10px',
  },
  contentRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItem: 'center',
    padding: ' 2px 30px 0px 30px',
  },
  brandlogo: {
    width: '80px',
    height: '80px',
    position: 'fixed',
    left: '20px',
    top: 'calc(40% - 50px)',
    borderRadius: '16px',
    backgroundColor: 'black',
    padding: '15px',
    boxShadow: '3px 3px 9px rgba(0,0,0,0.2)',
  },
  logoInner: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  headerInner: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    color: 'white',
    fontWeight: 300,
    marginRight: '10px'
  },
  contentBlok: {
    width: '49%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },

  card: {
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    borderRadius: '11px',
    backgroundColor: 'rgb(33,33,33)',
    marginBottom: '6px',
    boxShadow: '0px 0px 2px rgba(255,255,255,0.16)',
    outline: 'none',
    border: '0px',
  },
  logoCol: {
    width: '20%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  distanceCol: {
    width: '40%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 300,
  },
  infoCol: {
    width: '40%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
  },
  logo: {
    width: '40px',
    height: '50px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'center',
    fontSize: '8px'
  },
  values: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontWeight: 300,
  }

};

export default SiteDetail;
