import React from "react";
import { Controller } from "react-hook-form";
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from "@mui/material";

export const FormInputSwitch = ({ name, control, label }) => {
  const Item = styled(FormControlLabel)(({ theme }) => ({
    margin: '3%',
  }));
  return (
      <Controller
          name={name}
          control={control}
          render={({
            field
          }) =>
              <Item control={<Switch {...field} />} label={label}/>
          }
      />
  )
};
