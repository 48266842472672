import React from 'react';
import { Box } from '@mui/material';
import BotttomNavigation from '../../components/BottomNavigation';


function Profiel(props) {
  return (
    <Box sx={{...styles.box}}>
    <BotttomNavigation />
   Profile page
  </Box>
  );
}


const styles = {
  box: {
      width: '100%',
      height: '100%',
      display:'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItem: 'center',
      backgroundColor: 'rgba(40,38,38,1)',
      overflow: 'hidden',
      padding:'10px 10px 0px 10px',
  },
};

export default Profiel;