import React from "react";
import { Box, Container } from '@mui/material';
import {service}from '../../fakedata';


function MyServices({ style }) {




const serviceItem = (data) => (
    <Container
        key={data.id}
        sx={{...styles.serviceItem}}
    >

        <div style={{...styles.logo, backgroundImage: `url(service/${data.serviceImg})`}}></div>
    </Container>
)



    return (
        <Box sx={{...styles.box, ...style}}>
            <Container sx={{...styles.header}}>Services</Container>
            <Container sx={{...styles.service}}>
            {service && service.slice(0, 3).map(serviceItem)}
            </Container>
            
        
        </Box>
    );

}

const styles = {
    box: {
        width: '100%',
        height: '100%',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'center',
        backgroundColor: 'rgb(216,213,213)',
        overflow: 'hidden',
        padding:'10px 10px 10px 10px',
        borderRadius:'11px',
    },
    header: {
        width: '100%',
        height: '40px',
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'center',
        color: 'rgb(10,10,10)',
        fontSize: '20px',
        fontWeight:700,
  },
    service: {
        width: '100%',
        height: 'calc(100% - 60px)',
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        flexWrap: 'wrap',
        overflow: 'hidden',
    },
        serviceItem: {
        width: '30%',
        height: '100%',
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        borderRadius:'10px',
        backgroundColor: 'rgb(100,100,100)',
        boxShadow: '0px 0px 4px rgba(255,255,255,0.16)',
    },
    logo:{
        width:'100%',
        height:'100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
   
    
  };





export default MyServices