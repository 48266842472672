import { Button, Container } from "@mui/material";

const PrimaryButton = ({onClick, children, style, buttonStyle}) => {
  return (
      <Container sx={{ ...styles.buttonContainer, ...style }}>
        <Button
            onClick={onClick}
            sx={{ ...styles.buttonText, ...buttonStyle }}
            variant="contained"
            color = 'info'
        >
          {children}
        </Button>
      </Container>
  )
}

const styles = {
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingVertical: 10,
    alignItems: 'center',
  },
  buttonText: {
    "&.MuiSelected": {},
    "&.MuiFocusVisible": {
     
    },
    ":hover": {
      
    },
    width: '50%',
    height:'40px',
    fontFamily: 'Roboto',
    color: '#FFF',
    fontSize: "20px",
    borderRadius: '20px',
    fontWeight: '700',
  }
   
};





export default PrimaryButton
