import React from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import PrimaryButton from "../../components/PrimaryButton";
import { FormInputText } from "../../components/FormInputText";
import { FormInputSwitch } from "../../components/FormInputSwitch";
import bgImage from '../../assets/images/porscheEmail40.png';

function Login(props) {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
      ownsEV: false,
      consent: false,
    }
  });

  const onSubmit = (data) => {
    console.log(data);
    //temp action
    navigate('/dashboard')
  };

 
  return (
    <Box sx={{...styles.container }}
          display="flex"
          flexDirection="column"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          height="100%"
      >
        <Container sx={{...styles.header}}>Enter your credentials</Container>
        <FormInputText name="password" control={control} label="Email" />
        <FormInputText name="email" control={control} label="Password" />
        <FormInputSwitch name="ownsEV" control={control} label="Do you have an electric vehicle" />
        <FormInputSwitch name="consent" control={control} label="Consent" />
        <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            style={{ alignSelf: 'center' }}
            type="submit"
        >
          Login
        </PrimaryButton>
      </Box>
  );
}


const styles = {
  container: {
    display:'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
  },
  header:{
    width:'100%',
    display:'flex',
    justifyContent: 'center',
    alignContent: 'center',
    FontFace: 'Roboto',
    fontWeight: 700,
    fontSize: "16px",
    marginTop:'20px'
  }
};

export default Login;
