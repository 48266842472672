import React from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BotttomNavigation from '../../components/BottomNavigation';

import { siteData } from '../../fakedata';


function ChargingOffers(props) {
  const navigate = useNavigate();


  const handleSelectListItem = (data) => {
    //here action to detail screen
    navigate('/sitedetails',{state:{data}});
  };

  const listItem = (data) => {
    return(
      <div
        key={data.id}
        style={{...styles.card}}
        onClick={()=>handleSelectListItem(data)}
      >
        <div style={{...styles.logoCol}}>
          <div style={{...styles.logo, backgroundImage: `url(brand/${data.brandImg})`}}></div>
        </div>
        <div style={{...styles.distanceCol}} >
        <span style={{fontSize:'24px'}}>{data.distance}<span style={{fontSize:'16px'}}>&nbsp; km</span></span>
          {/* <div style={{fontSize:'24px', marginRight:'10px'}}>{data.distance}</div> */}
          {/* <div style={{fontSize:'16px'}}> km</div> */}
        </div>
        <div style={{...styles.infoCol}}>
          <div style={{...styles.infoTop}}>
            <div style={{...styles.label}} >Charge time to 80%</div>
            <div style={{...styles.values}}>
            <span style={{fontSize:'24px'}}>{data.chargeTime}<span style={{fontSize:'14px'}}>&nbsp; min</span></span>
            </div>
          </div>
          <div style={{...styles.infoTop}}>
            <div style={{...styles.label}} >Price per kwh</div>
            <div style={{...styles.values}}>
              <div style={{fontSize:'24px', marginRight:'8px'}}>{`€ ${data.price}`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }




  return (
        <Box sx={{...styles.box}} >
          <BotttomNavigation />
          <Container sx={{...styles.header}}>Charging offers</Container>
            {siteData && siteData.map(listItem )}
        </Box>
  );
};



const styles = {
  box: {
      width: '100%',
      height: '100%',
      display:'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'center',
      backgroundColor: 'rgba(40,38,38,1)',
      overflow: 'hidden',
      overflowY: 'auto',
      padding:'10px 10px 10px 10px',
  },
  header: {
      width: '100%',
      height: '40px',
      display:'flex',
      justifyContent: 'flex-start',
      alignContent: 'center',
      color: 'white',
      fontSize: '20px',
      fontWeight:700,
  },
  card: {
      width: '100%',
      height: '100px',
      display:'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      borderRadius: '11px',
      backgroundColor: 'rgb(33,33,33)',
      marginBottom:'6px',
      boxShadow: '0px 0px 2px rgba(255,255,255,0.16)',
      outline: 'none',
      border: '0px',
      padding:'5px 10px 5px 10px',
  },
  logoCol: {
      width: '20%',
      height: '100%',
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
  },
  distanceCol: {
      width: '40%',
      height: '100%',
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontWeight: 300,
  },
  infoCol: {
    width: '40%',
    height: '100%',
    display:'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
  },
  logo:{
      width:'40px',
      height:'50px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
  },
  label:{
      display:'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignContent: 'center',
      fontSize:'8px'
  },
  values:{
    display:'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
      fontWeight:300,
  }

};


export default ChargingOffers;
