import React from 'react';
import { Box, Container } from "@mui/material";
import BotttomNavigation from '../../components/BottomNavigation';

import MyCar from '../../components/MyCar';
import MyProfile from '../profiel/MyProfile';
import ChargeNow from '../../components/ChargeNow';
import MyServices from '../services/MyServices';


function Dashboard(props) {
  return (
        <Box sx={{...styles.box}}>
          <BotttomNavigation />
            <Container sx={{...styles.header}}><span style={{fontWeight:300}}>Hello<span style={{fontWeight:700}}>&nbsp; Sebastian</span></span>
          </Container>
          <Container sx={{...styles.dashboard}}>
            <MyCar style={{height:'25%', marginBottom:'4%'}}/>
            <MyProfile style={{height:'25%', width:'48%', marginRight:'4%'}}/>
            <ChargeNow style={{height:'25%', width:'48%'}}/>
            <MyServices style={{height:'25%', marginTop:'4%'}}/>


          </Container>
          
          
        </Box>
  );
}


const styles = {
  box: {
      width: '100%',
      height: '100%',
      display:'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItem: 'center',
      backgroundColor: 'rgba(40,38,38,1)',
      overflow: 'hidden',
      padding:'10px 0px 0px 0px',
  },
  header: {
      width: '100%',
      height: '40px',
      display:'flex',
      justifyContent: 'flex-start',
      alignContent: 'center',
      color: 'white',
      fontSize: '20px',
      fontWeight:700,
      marginBottom:'10px',
},
  dashboard: {
      width: '100%',
      height: 'calc(100% - 50px)',
      display:'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',

  },
};


export default Dashboard;
