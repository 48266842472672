import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import ChargingOffers from "../pages/chargingOffers";
import Services from "../pages/services";
import Prefs from "../pages/prefs";
import You from "../pages/profiel";
import SiteDetail from "../pages/chargingOffers/siteDetail";
import Reservations from "../pages/reservations";

const Home = lazy(() => import('../pages/home'));

function AppRoutes(props) {
  return (
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/dashboard" element={<Dashboard />}/>
        <Route path="/chargingoffers" element={<ChargingOffers />}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/prefs" element={<Prefs />}/>
        <Route path="/you" element={<You />}/>
        <Route path="/sitedetails" element={<SiteDetail />}/>
        <Route path="/reservations" element={<Reservations />}/>
      </Routes>
  );
}

export default AppRoutes;
