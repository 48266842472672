import { Button, Container } from "@mui/material";

const SecondaryButton = ({onClick, children, containerStyle, buttonStyle}) => {
  return (
      <Container sx={{ ...styles.container, ...containerStyle }}>
        <Button
            onClick={onClick}
            sx={{ ...styles.button, ...buttonStyle }}
            variant="contained"
        >
          {children}
        </Button>
      </Container>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingVertical: 10,
    alignItems: 'center',
    
  },
  button: {
    "&.MuiSelected": {},
    "&.MuiFocusVisible": {
      color: '#212121',
      backgroundColor: '#C4C3C4',
    },
    ":hover": {
      color: '#212121',
      backgroundColor: '#C4C3C4',
    },
    width: '50%',
    height:'40px',
    fontFamily: 'Roboto',
    color: '#fff',
    fontSize: "20px",
    border:'1px solid #C4C3C4',
    backgroundColor: '#212121',
    borderRadius: '20px',
    fontWeight: '700',
  }
};

export default SecondaryButton
