import React from "react";
import { Box, Container } from '@mui/material';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';


function MyCar({ style }) {

const car = [
    { 
        carName: 'Porsche Tycan',
        carImg: 'porsche-taycan-model.png',
        ActualRange: '224 km',
    },
      
];
    
    return (
        <Box sx={{...styles.box, ...style, backgroundImage: `url(cars/${car[0].carImg})`}}>
            <Container sx={{...styles.infoContainer}}>
            <span style={{...styles.batInfo}}><Battery3BarIcon sx={{...styles.batIcon}}/><span> &nbsp; {car[0].ActualRange}</span></span>
            <span style={{fontSize:'16px', fontWeight:700}}>{car[0].carName}</span>
            </Container>
        
        </Box>
    );

}

const styles = {
    box: {
        width: '100%',
        height: '10%',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        backgroundColor: 'rgb(220,237,244)',
        overflow: 'hidden',
        padding:'5px 5px 1px 5px',
        borderRadius:'11px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
    header: {
        width: '100%',
        height: '40px',
        display:'flex',
        justifyContent: 'flex-start',
        alignContent: 'center',
        color: 'white',
        fontSize: '20px',
        fontWeight:700,
    },
    infoContainer: {
        width: '100%',
       
        display:'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignContent: 'center',
        color:'rgb(10,10,10)',
        borderRadius:'8px'
    },
    batInfo:{
        display:'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    batIcon: {
        transform: 'rotate(90deg)',
    },
    
    
  };





export default MyCar