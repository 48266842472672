import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';


function ChargeNow({ style }) {
    const navigate = useNavigate();
    
    return (
        <Box sx={{...styles.box, ...style}} onClick={() => navigate('/chargingoffers')}>
            <Container sx={{...styles.container}}>
                <div><BoltIcon sx={{...styles.icon}} /></div>
                </Container>
            <Container sx={{...styles.container}}>Charge Now</Container>
        </Box>
    );

}

const styles = {
    box: {
        width: '50%',
        height: '10%',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'center',
        backgroundColor: 'rgb(245,203,207)',
        overflow: 'hidden',
        padding:'20px 5px 20px 5px',
        borderRadius:'11px',
    },
    container: {
        width: '100%',
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        color:'rgb(10,10,10)',
        fontSize: '20px',
        fontWeight:700,
    },
    icon: {
        fontSize:'70px',
        color:'rgb(10,10,10)',
    },
   
    
  };





export default ChargeNow