import React from "react";
import { Box, Container } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


function MyProfile({ style }) {

    return (
        <Box sx={{...styles.box, ...style}}>
            <Container sx={{...styles.container}}>
                <div><AccountCircleIcon sx={{...styles.icon}} /></div>
                </Container>
            <Container sx={{...styles.container}}>Sebastian</Container>
        </Box>
    );

}

const styles = {
    box: {
        width: '50%',
        height: '10%',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: 'rgb(240,234,228)',
        overflow: 'hidden',
        padding:'5px 5px 1px 5px',
        borderRadius:'11px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
    container: {
        width: '100%',
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        color:'rgb(10,10,10)',
        fontSize: '20px',
        fontWeight:700,
    },
    icon: {
        fontSize:'70px',
        color:'rgb(10,10,10)',
    },
   
   
    
  };





export default MyProfile