import React from 'react';
import { Backdrop, Box, CircularProgress, Container } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import BotttomNavigation from '../../components/BottomNavigation';
import { service } from '../../fakedata';
import SecondaryButton from "../../components/SecondaryButton";

function Reservations(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.data;
  const [open, setOpen] = React.useState(false);

  const handleOnClickService = (data) => {
  };

  const handleCancelReservationClick = () => {
    setOpen(true);
    fetch(
        `https://charge01.eleqtramove.com/csat/api/chargers/${data.chargeBoxId}/reservations/active/cancel`,
        {
          method: 'PUT'
        })
    .then(response => {
      setOpen(false);
      navigate('/dashboard');
    })
    .catch(error => {
      setOpen(false);
    });
  };

  const serviceItem = (service) => {
    return (
        <div
            style={{ ...styles.serviceCard }}
            key={service.id}
            onClick={() => handleOnClickService(service)}
        >
          {service.serviceName}

        </div>
    );
  };

  return (
      <Box sx={{ ...styles.box }}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
        <BotttomNavigation/>
        <Container sx={{ ...styles.header }}>Charging Reservation</Container>
        <Container sx={{ ...styles.reservationCard }}>

          <div style={{ ...styles.logoCol }}>
            <div style={{ ...styles.logo, backgroundImage: `url(brand/${data.brandImg})` }}></div>
            <span style={{ fontSize: '24px' }}>{data.distance}<span style={{ fontSize: '16px' }}>&nbsp; km</span></span>
          </div>

          <div style={{ ...styles.infoCol }}>
            <div style={{ ...styles.infoTop }}>
              <div style={{ ...styles.label }}>Charge time to 80%</div>
              <div style={{ ...styles.values }}>
                <span style={{ fontSize: '32px' }}>{data.chargeTime}<span style={{ fontSize: '24px' }}>&nbsp; min</span></span>
              </div>
            </div>

            <div style={{ ...styles.infoTop }}>
              <div style={{ ...styles.label }}>Price per kwh</div>
              <div style={{ ...styles.values }}>
                <div style={{ fontSize: '32px', marginRight: '8px' }}>{`€ ${data.price}`}</div>
              </div>
            </div>
          </div>
          <div style={styles.buttonWrapper}>
            {/*<SecondaryButton variant="outlined" startIcon={<CancelOutlinedIcon/>} onClick={() => handleCancelReservationClick()}>Cancel</SecondaryButton>*/}
            <SecondaryButton onClick={() => handleCancelReservationClick()}>Cancel</SecondaryButton>
          </div>
          <div style={{ ...styles.reservedBanner }}>Reserved</div>
        </Container>
        <Container sx={{ ...styles.serviceContainer }}>
          {service && service.map(serviceItem)}
        </Container>
      </Box>
  );
}

const styles = {
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItem: 'center',
    backgroundColor: 'rgba(40,38,38,1)',
    overflow: 'hidden',
    padding: '10px 10px 0px 10px',
  },
  header: {
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    color: 'white',
    fontSize: '20px',
    fontWeight: 700,
  },
  reservationCard: {
    width: '100%',
    height: '30%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    backgroundColor: 'rgb(10,10,10)',
    marginBottom: '6px',
    padding: '5px 0px 0px 0px',
    borderRadius: '11px',
    boxShadow: '0px 0px 4px rgba(255,255,255,0.16)',
  },
  logoCol: {
    width: '40%',
    height: '75%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    paddingLeft: '20px',
  },
  infoCol: {
    width: '60%',
    height: '75%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    paddingRight: '20px',
  },
  logo: {
    width: '60px',
    height: '60px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'center',
    fontSize: '12px',
    fontWeight: 700,
  },
  values: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontWeight: 300,
  },
  reservedBanner: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItem: 'center',
    backgroundColor: 'rgba(98,198,121,1)',
    color: 'white',
    fontSize: '20px',
    fontWeight: 700,
    borderRadius: '0px 0px 11px 11px',
    padding: '5px',
  },
  serviceContainer: {
    width: '100%',
    height: '70%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    padding: '10px 0px 0px 0px',
    marginTop: '20px',
  },
  serviceCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItem: 'Center',
    backgroundColor: 'rgb(10,10,10)',
    marginBottom: '6px',
    padding: '10px',
    borderRadius: '11px',
    boxShadow: '0px 0px 4px rgba(255,255,255,0.16)',
  },
  button: {
    alignSelf: 'center',
  },
  buttonWrapper: {
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: '5px',

  }
};

export default Reservations;
