import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import {Container, IconButton } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SettingsIcon from '@mui/icons-material/Settings';
import BoltIcon from '@mui/icons-material/Bolt';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';






function BottomNavigation(props) {
    const [checked, setChecked] = React.useState(false);
    const navigate = useNavigate();


    const handleChange = () => {
        setChecked((prev) => !prev);
      };

     
  return (
        <div style={{...styles.box }} className={checked ? "slide-up" : "slide-down"}>
            <Container sx={{...styles.header}}>
                <button style={{...styles.slideBtn }}  onClick={() => handleChange()}></button>
            </Container>
            <div style={{...styles.navholder}}>
                <div style={{...styles.navcol}}>
                    <IconButton sx={{...styles.iconbtn}} onClick={() =>  navigate('/dashboard')} color="primary" aria-label="upload picture" component="label">
                        <DirectionsCarIcon sx={{...styles.navicon}}/>
                        <div style={{...styles.icontxt}} >My Car</div>
                    </IconButton >
                    <IconButton sx={{...styles.iconbtn}}  onClick={() =>  navigate('/prefs')} color="primary" aria-label="upload picture" component="label">
                        <SettingsIcon sx={{...styles.navicon}}/>
                        <div style={{...styles.icontxt}} >Preferences</div>
                    </IconButton>

                </div>
                <div style={{...styles.secondCol}}>
                    <IconButton sx={{...styles.iconbtn}}  onClick={() =>  navigate('/chargingoffers')} color="primary" aria-label="upload picture" component="label">
                        <BoltIcon sx={{...styles.navicon2}}/>
                        <div style={{...styles.icontxt, fontWeight:700, fontSize:'14px'}} >Charge Now</div>
                    </IconButton>
                </div>
                <div style={{...styles.navcol}}>
                    <IconButton sx={{...styles.iconbtn}} onClick={() =>  navigate('/services')} color="primary" aria-label="upload picture" component="label">
                        <LocalGroceryStoreIcon sx={{...styles.navicon}}/>
                        <div style={{...styles.icontxt}} >Services</div>
                    </IconButton>
                    <IconButton sx={{...styles.iconbtn}} onClick={() =>  navigate('/you')} color="primary" aria-label="upload picture" component="label">
                        <SettingsAccessibilityIcon  sx={{...styles.navicon}}/>
                        <div style={{...styles.icontxt}} >You</div>
                    </IconButton>
                </div>
            </div>
        </div>
  );
}


const styles = {
    box: {
        width: '100%',
        height: '300px',
        display:'flex',
        position: 'fixed',
        left: '0px',
        bottom: '-200px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'center',
        zIndex: 1000,
        borderRadius: '50px 50px 0px 0px',
        backgroundColor: 'black',
        boxShadow: '0px 3px 9px rgba(255,255,255,0.16)',
        overflow: 'hidden'
    },
    header: {
        width: '100%',
        height: '10px',
        display:'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
    slideBtn: {
        width: '10%',
        height: '4px',
        display:'flex',
        borderRadius: '2px',
        boxShadow: '0px 3px 6px #C4C3C4',
        backgroundColor: '#C4C3C4',
        outline: 'none',
        opacity:'0.7',
    },
    navholder: {
        width: '100%',
        height: '100%',
        display:'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        padding:'5px 15px 15px 15px'
    },
    navcol: {
        width: 'calc(100% / 3)',
        height: '100%',
        display:'flex',
        justifyContent: 'flex-start',
        alignContent: 'center',
        flexDirection: 'column',
        flexWrap: 'no-wrap',
    },
    secondCol: {
        width: 'calc(100% / 3)',
        height: '180px',
        display:'flex',
        justifyContent: 'flex-start',
        alignContent: 'center',
        flexDirection: 'column',
        flexWrap: 'no-wrap',
    },
    iconbtn:{
        width: '100%',
        height: '80px',
        display:'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        flexWrap: 'no-wrap',
        borderRadius: '0px',
    },
    icontxt:{
        width: '100%',
        color: 'white',
        fontSize: '12px',
    },
    navicon:{
        color: 'white',
        fontSize: '30px',
    },
    navicon2:{
        color: 'white',
        fontSize: '50px'
    }
  };

export default BottomNavigation;